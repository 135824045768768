import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
    Form,
    LeftContainer,
    RightContainer,
    SideWrapper,
    TextContainer
} from '../components/SideWrapper/SideWrapper.styles';
import LogoAndText from '../components/LogoAndText';
import { verifyEmail, checkVerificationStatus } from "../utils/api-lists/verification.api";

const EmailVerificationPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [token, setToken] = useState(new URLSearchParams(location.search).get('token') || '');
    const [error, setError] = useState(null);
    const [verificationSuccessful, setVerificationSuccessful] = useState(false);

    useEffect(() => {
        if (!token) {
            navigate('/');
            return;
        }

        const fetchEmail = async () => {
            try {
                const response = await checkVerificationStatus(token);
                if (response.email) {
                    setEmail(response.email);
                } else {
                    setError(response.message || 'Error fetching email.');
                }
            } catch (err) {
                setError(err.message);
            }
        };

        fetchEmail();
    }, [token, navigate]);


    useEffect(() => {
        if (verificationSuccessful) {
            const timer = setTimeout(() => {
                navigate('/login');
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [verificationSuccessful, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await verifyEmail(email, token);
            if (response.message === 'Email verified successfully!') {
                setVerificationSuccessful(true);
                setError(null);
            } else {
                setError(response.message || 'Verification failed.');
            }
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <SideWrapper>
            <LeftContainer>
                <LogoAndText />
            </LeftContainer>
            <RightContainer>
                <h2>Email Verification</h2>
                <TextContainer>
                    <h3>Please verify your email to continue.</h3>
                </TextContainer>
                    <Form onSubmit={handleSubmit} style={{ marginTop: "121px", maxWidth: "400px" }}>
                        <label>Email</label>
                        <input placeholder='Email' name='email' type='email' defaultValue={email} disabled />
                        <input name='token' type='text' value={token} hidden />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        {verificationSuccessful ? (
                            <button type="button" style={{
                                backgroundColor: 'green', 
                                color: 'white', 
                                width: '100%',
                            }}>
                                Successfully Verified
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-4">
                                Complete
                            </button>
                        )}

                    </Form>
            </RightContainer>
        </SideWrapper>
    );
}

export default EmailVerificationPage;
