import React, {useContext} from "react";
import {Wrapper} from "./WelcomeBanner.styles";
import {UserContext} from '../../context/UserContext';

const WelcomeBanner = () => {
    const {user} = useContext(UserContext);
    if (!user) {
        return <p>No user information available</p>;
    }

    return (
        <Wrapper>
            <p>Hi, Doctor {user.firstName}!</p>
            <h3>Welcome To Glacies</h3>
        </Wrapper>
    )
};

export default WelcomeBanner;