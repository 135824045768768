import React from 'react';

// Components
import {DashboardInner, DashboardWrapper} from '../components/DashboardWrapper/DashboardWrapper.styles';
import DashboardHeader from '../components/DashboardHeader';
import SelectPatient from '../components/SelectPatient';

const SelectPatientPage = () => {

    return (
        <DashboardWrapper>
            <DashboardHeader/>
            <DashboardInner>
                <SelectPatient/>
            </DashboardInner>
        </DashboardWrapper>
    );
}

export default SelectPatientPage;
