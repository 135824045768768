import {
    Form,
    LeftContainer,
    RightContainer,
    SideWrapper,
    TextContainer
} from "../components/SideWrapper/SideWrapper.styles";
import LogoAndText from "../components/LogoAndText";
import SignOptions from "../components/SignOptions";
import React, {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../context/UserContext";
import {forgetPassword} from "../utils/api-lists/login.api";

const ForgetPasswordPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const {loginUser} = useContext(UserContext);

    const handleSubmit = async (e) => {
        console.log('Submit');
        e.preventDefault();
        try {
            const user = await forgetPassword(email);
            if (user) {
                navigate('/success');
            }
        } catch (err) {
            setError(err);
        }
    };

    return (
        <SideWrapper>
            <LeftContainer>
                <LogoAndText/>
            </LeftContainer>
            <RightContainer>
                <h2>Forget Password?</h2>
                <TextContainer>
                    <h3>Please enter your mail to continue</h3>
                </TextContainer>
                <Form onSubmit={handleSubmit} style={{marginTop: "121px", maxWidth: "400px"}}>
                    <label>Email</label>
                    <input placeholder='Email' name='email' type='email' onChange={(e) => setEmail(e.target.value)}/>
                    {error && <p style={{color: 'red'}}>{error}</p>}
                    <button type="submit" className="btn btn-4">Next</button>
                </Form>
                <SignOptions signIn={true}/>
                <p>Don't have an account? <span onClick={() => navigate('/sign-up')}>Sign up</span></p>
            </RightContainer>
        </SideWrapper>
    );
}

export default ForgetPasswordPage;