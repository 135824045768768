import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;

    gap: 50px;
    margin-top: 20px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 270px;
    border-radius: 10px;
    background: rgba(88, 80, 109, 0.30);
    padding: 0 25px;
`;

export const NewTestImgContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 269px;
    border-radius: 20px;
    background: rgba(88, 80, 109, 0.30);
    margin: 45px 0 39px 0;
`;

export const PatientsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px 0 36px 0;
    gap: 7px;
`;

export const Patient = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    padding-bottom: 7px;

    div {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    p {
        font-size: 14px;
    }

    @media (max-width: 460px) {
        #patient-email {
            display: none;
        }
    }
`;

export const PatientsContainerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 39px;

    div {
        display: flex;
        gap: 20px;

        Icon {
            cursor: pointer
        }
    ;
    }

    h5 {
        font-weight: 600;
    }
`;
