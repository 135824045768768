import React from 'react';

// Components
import { Wrapper, DashboardWrapper, DashboardInner } from '../components/DashboardWrapper/DashboardWrapper.styles';
import { FeaturesWrapper } from '../components/FeaturesContainer/FeaturesContainer.styles';
import SideMenu from '../components/SideMenu';
import DashboardHeader from '../components/DashboardHeader';
import Feature from '../components/Feature';

import AddIcon from "../assets/add-image.svg"
import MicrobiomeIcon from "../assets/microbiome.svg"
import QuestinaireIcon from "../assets/questinaire.svg"

const NewTestPage = () => {

    return (
        <DashboardWrapper>
            <DashboardHeader />
            <DashboardInner>
                <p>Features</p>
                <FeaturesWrapper>
                    <Feature image={AddIcon} text="Add image for analysis" redirect="/analysis" />
                    <Feature image={MicrobiomeIcon} text="Get Microbiome kit" />
                    <Feature image={QuestinaireIcon} text="Take questionnaire" />
                </FeaturesWrapper>
            </DashboardInner>
        </DashboardWrapper>
    );

}

export default NewTestPage;
