import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Section = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    flex-wrap: wrap;

    @media (min-width: 501px) {
        & > input {
            margin-left: 48px;
        }
    }

    & > * {
        display: flex;
        align-items: center;
        flex: 1;
        @media (max-width: 500px) {
            flex: 0 1 100%;
            margin-top: 10px;
        }

        &:last-child {
            justify-content: center;
        }
    }
`;