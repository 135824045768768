import React, { useRef, useEffect, useState } from 'react';
import * as faceapi from 'face-api.js';

function FaceDetection() {
  const videoRef = useRef(null);
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + '/models';
      try {
        await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
        await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
        setModelsLoaded(true);
      } catch (error) {
        console.error('Error loading models:', error);
        setErrorMessages(['Error loading models.']);
      }
    };

    loadModels();
  }, []);

  useEffect(() => {
    if (!modelsLoaded) return;

    const video = videoRef.current;

    navigator.mediaDevices
      .getUserMedia({ video: {} })
      .then((stream) => {
        video.srcObject = stream;
      })
      .catch((err) => {
        console.error('Error accessing webcam:', err);
        setErrorMessages(['Cannot access webcam. Please allow camera access.']);
      });

    let canvas;
    let interval;
    let centerX, centerY, radiusX, radiusY;
    const rotation = 0;

    const calculateEllipseParameters = () => {
      const rect = video.getBoundingClientRect();
      const currentWidth = rect.width;
      const currentHeight = rect.height;

      centerX = currentWidth / 2;
      centerY = currentHeight / 2;
      radiusX = currentWidth / 3;
      radiusY = currentHeight / 2.5;

      if (canvas) {
        canvas.width = currentWidth;
        canvas.height = currentHeight;
      }
    };

    const onPlay = () => {
      canvas = faceapi.createCanvasFromMedia(video);

      canvas.style.position = 'absolute';
      canvas.style.top = '0';
      canvas.style.left = '0';
      canvas.style.width = '100%';
      canvas.style.height = 'auto';
      canvas.style.zIndex = '1';

      document.getElementById('video-container').append(canvas);

      const ctx = canvas.getContext('2d', { willReadFrequently: true });

      calculateEllipseParameters();

      window.addEventListener('resize', calculateEllipseParameters);

      interval = setInterval(async () => {
        try {
          const detections = await faceapi
            .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
            .withFaceLandmarks();

          const rect = video.getBoundingClientRect();
          const displaySize = { width: rect.width, height: rect.height };
          const resizedDetections = faceapi.resizeResults(detections, displaySize);

          ctx.clearRect(0, 0, canvas.width, canvas.height);

          drawEllipse(ctx, centerX, centerY, radiusX, radiusY, rotation);

          if (resizedDetections.length > 0) {
            const landmarks = resizedDetections[0].landmarks;
            const positions = landmarks.positions;

            // Draw facial landmarks
            faceapi.draw.drawFaceLandmarks(canvas, resizedDetections);

            const forehead = calculateForehead(positions);

            const chin = positions[8];
            const leftFace = positions[0];
            const rightFace = positions[16];
            const pointsToCheck = [leftFace, rightFace, chin, forehead];

            let isInsideEllipse = true;
            for (const point of pointsToCheck) {
              const inside = isPointInEllipse(
                point.x,
                point.y,
                centerX,
                centerY,
                radiusX,
                radiusY,
                rotation
              );
              if (!inside) {
                isInsideEllipse = false;
                break;
              }
            }

            ctx.fillStyle = 'red';
            pointsToCheck.forEach((point) => {
              ctx.beginPath();
              ctx.arc(point.x, point.y, 2, 0, 2 * Math.PI);
              ctx.fill();
            });

            // Check face distance
            const foreheadChinDistance = euclideanDistance(forehead, chin);
            const closeThreshold = 0.9 * (2 * radiusY);
            const farThreshold = 0.6 * (2 * radiusY);

            let faceDistanceMessage = '';
            if (foreheadChinDistance > closeThreshold) {
              faceDistanceMessage = 'Face is too close';
            } else if (foreheadChinDistance < farThreshold) {
              faceDistanceMessage = 'Face is too far';
            }

            // Analyze lighting conditions
            const frame = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const { meanBrightness, contrast } = calculateBrightnessAndContrast(frame);
            const blurValue = estimateBlur(frame);
            const brightSpotPercentage = detectBrightSpots(frame);

            const brightnessThreshold = 80;
            const contrastThreshold = 40;
            const blurThreshold = 20;
            const brightSpotThreshold = 5;

            let badLighting = false;
            let lightingMessages = [];

            if (meanBrightness < brightnessThreshold) {
              badLighting = true;
              lightingMessages.push('Too dark');
            }

            if (contrast < contrastThreshold) {
              badLighting = true;
              lightingMessages.push('Low contrast');
            }

            if (blurValue < blurThreshold) {
              badLighting = true;
              lightingMessages.push('Image is blurry');
            }

            if (brightSpotPercentage > brightSpotThreshold) {
              badLighting = true;
              lightingMessages.push('Bright spot detected');
            }

            // Collect error messages
            const messages = [];
            if (!isInsideEllipse) {
              messages.push('Face not in boundary');
            }
            if (faceDistanceMessage) {
              messages.push(faceDistanceMessage);
            }
            if (badLighting) {
              messages.push(...lightingMessages);
            }

            // Draw error messages
            drawErrorMessage(ctx, messages, 10, 30);
          } else {
            // No face detected
            drawErrorMessage(ctx, ['No face detected'], 10, 30);
          }
        } catch (error) {
          console.error('Error during detection:', error);
        }
      }, 100);
    };

    video.addEventListener('play', onPlay);

    return () => {
      clearInterval(interval);
      video.removeEventListener('play', onPlay);
      window.removeEventListener('resize', calculateEllipseParameters);
    };
  }, [modelsLoaded]);

  return (
    <div style={{ position: 'relative', maxWidth: '100%', margin: '0 auto' }}>
      <div
        id="video-container"
        style={{ position: 'relative', width: '100%', height: 'auto' }}
      >
        <video
          ref={videoRef}
          autoPlay
          muted
          style={{ width: '100%', height: 'auto' }}
        />
        {/* The canvas will be appended here */}
      </div>
    </div>
  );
}

function drawEllipse(ctx, centerX, centerY, radiusX, radiusY, rotation, color = 'green') {
  ctx.beginPath();
  ctx.ellipse(centerX, centerY, radiusX, radiusY, rotation, 0, 2 * Math.PI);
  ctx.strokeStyle = color;
  ctx.lineWidth = 2;
  ctx.stroke();
}

function isPointInEllipse(x, y, centerX, centerY, radiusX, radiusY, rotation) {
  const cos = Math.cos(-rotation);
  const sin = Math.sin(-rotation);

  const dx = x - centerX;
  const dy = y - centerY;

  const tx = dx * cos - dy * sin;
  const ty = dx * sin + dy * cos;

  return (tx * tx) / (radiusX * radiusX) + (ty * ty) / (radiusY * radiusY) <= 1;
}

function euclideanDistance(point1, point2) {
  return Math.sqrt(
    Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2)
  );
}

function calculateForehead(positions) {
  const point27 = positions[27];
  const point33 = positions[33];

  const forehead = {
    x: point27.x + (point27.x - point33.x),
    y: point27.y + (point27.y - point33.y),
  };

  return forehead;
}

function calculateBrightnessAndContrast(imageData) {
  const data = imageData.data;
  let sum = 0;
  let sumSquared = 0;
  const numPixels = data.length / 4;

  for (let i = 0; i < data.length; i += 4) {
    const brightness = 0.21 * data[i] + 0.72 * data[i + 1] + 0.07 * data[i + 2];
    sum += brightness;
    sumSquared += brightness * brightness;
  }

  const meanBrightness = sum / numPixels;
  const variance = sumSquared / numPixels - meanBrightness * meanBrightness;
  const contrast = Math.sqrt(variance);

  return { meanBrightness, contrast };
}

function estimateBlur(imageData) {
  const width = imageData.width;
  const height = imageData.height;
  const data = imageData.data;

  let total = 0;
  let count = 0;

  for (let y = 0; y < height - 1; y++) {
    for (let x = 0; x < width - 1; x++) {
      const i = (y * width + x) * 4;

      const brightness = 0.21 * data[i] + 0.72 * data[i + 1] + 0.07 * data[i + 2];
      const brightnessRight = 0.21 * data[i + 4] + 0.72 * data[i + 5] + 0.07 * data[i + 6];
      const brightnessDown =
        0.21 * data[i + width * 4] +
        0.72 * data[i + width * 4 + 1] +
        0.07 * data[i + width * 4 + 2];

      const dx = brightness - brightnessRight;
      const dy = brightness - brightnessDown;

      total += dx * dx + dy * dy;
      count++;
    }
  }

  const avgGradient = total / count;

  return avgGradient;
}

function detectBrightSpots(imageData) {
  const data = imageData.data;
  const numPixels = data.length / 4;

  let brightPixelCount = 0;

  for (let i = 0; i < data.length; i += 4) {
    const brightness = 0.21 * data[i] + 0.72 * data[i + 1] + 0.07 * data[i + 2];

    if (brightness > 240) {
      brightPixelCount++;
    }
  }

  const brightPixelPercentage = (brightPixelCount / numPixels) * 100;

  return brightPixelPercentage;
}

function drawErrorMessage(ctx, messages, x, y) {
  ctx.font = '16px Arial';
  ctx.fillStyle = 'red';
  messages.forEach((message, index) => {
    ctx.fillText(message, x, y + index * 20);
  });
}

export default FaceDetection;
