import api from '../api';

export const getRecentVisits = async (patientId) => {
    try {
        const response = await api.get(`/patients/${patientId}/visits`, {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!(response.status === 200)) {
            throw new Error('Network response was not ok');
        }

        return await response.data;
    } catch (error) {
        throw error.message;
    }
};

export const createVisit = async (patientId, note, date) => {
    try {
        const response = await api.post(`/patients/${patientId}/visits`, 
            { note, date },
            {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
            });

        if (response.status !== 201) {
            throw new Error('Network response was not ok');
        }

        return await response.data;
    } catch (error) {
        throw new Error(error.message);
    }
};

export const getVisit = async (patientId, visitId) => {
    try {
        const response = await api.get(`/patients/${patientId}/visits/${visitId}`, {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (!(response.status === 200)) {
            throw new Error('Network response was not ok');
        }

        return await response.data;
    } catch (error) {
        throw error.message;
    }
};
