import React, {useContext, useState} from "react";
import {Form, Header, Wrapper} from "./NewPatient.styles";
import AddClientImage from "../AddClientImage";
import Input from "../Input";
import {createPatient} from '../../utils/api-lists/patient.api';
import {UserContext} from "../../context/UserContext";

const NewPatient = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const {user} = useContext(UserContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await createPatient(user.id, firstName, lastName, email, phone);
            console.log(response);
            setSuccess('Patient created successfully!');
            setError(null);
            setFirstName('');
            setLastName('');
            setEmail('');
            setPhone('');
        } catch (err) {
            setError(err);
            setSuccess(null);
        }
    };

    return (
        <Wrapper>
            <Header>
                <p>Register New Patient</p>
                <AddClientImage showText={true}/>
            </Header>
            <Form onSubmit={handleSubmit}>
                <Input type="text" name="name" placeholder='Name*' value={firstName}
                       onChange={(e) => setFirstName(e.target.value)} required/>
                <Input type="text" name="surname" placeholder='Surname*' value={lastName}
                       onChange={(e) => setLastName(e.target.value)} required/>
                <Input type="email" name="email" placeholder='Email' value={email}
                       onChange={(e) => setEmail(e.target.value)}/>
                <Input type="tel" name="phone" placeholder='Phone*' value={phone}
                       onChange={(e) => setPhone(e.target.value)} required/>
                {error && <p style={{color: 'red'}}>{error}</p>}
                {success && <p style={{color: 'green'}}>{success}</p>}
                <button type="submit" className="btn btn-2 btn-align-right">Submit</button>
            </Form>

        </Wrapper>
    )
};

export default NewPatient;
