import React from 'react';

// Components
import {DashboardWrapper} from '../../components/DashboardWrapper/DashboardWrapper.styles';
import PdfList from '../../components/PdfList';

const PdfPage = () => {
    return (
        <DashboardWrapper>
            <PdfList/>
        </DashboardWrapper>
    );
}

export default PdfPage;
