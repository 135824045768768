import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';
import {PatientContainer, PatientListContainer, PatientPhoto, Wrapper} from "./SelectPatient.styles";
import {getAllPatients} from '../../utils/api-lists/patient.api';
import Photo from "../../assets/photo.svg";
import {UserContext} from "../../context/UserContext";

const SelectPatient = () => {
    const navigate = useNavigate();

    const [patients, setPatients] = useState([]);
    const [error, setError] = useState(null);
    const {user} = useContext(UserContext);

    useEffect(() => {
        if (!user?.id) return;

        const fetchPatients = async () => {
            try {
                const data = await getAllPatients(user.id);
                setPatients(data);
            } catch (err) {
                setError(err);
            }
        };

        fetchPatients();
    }, [user?.id]);

    return (
        <Wrapper>
            <p>Please select patient</p>
            <PatientListContainer>
                {error && <p style={{color: 'red'}}>{error}</p>}
                {patients.slice().reverse().map((patient) => (
                    <PatientContainer key={patient.id} onClick={() => navigate('/new-test')}>
                        <p>{patient.firstName} {patient.lastName}</p>
                        <PatientPhoto src={Photo} alt="Patient"/>
                    </PatientContainer>
                ))}
            </PatientListContainer>
        </Wrapper>
    )
};

export default SelectPatient;
