import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    height: 20%;
    width: 100%;

    form {
        width: 250px;
    }
`

export const ListOfPdf = styled.div`
    display: flex;
    flex-direction: column;
    //align-items: center;
    //flex-direction: row-reverse;
    //justify-content: flex-end;
    margin-top: 20px;
    gap: 17px;
    overflow-x: auto;
    height: 100%;
    width: 100%;
    max-width: 600px;
    white-space: nowrap;
    pointer-events: ${props => props.loading ? 'none' : 'auto'};

`;

export const PdfContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 120px;
    max-width: 600px;
    height: 64px;
    background-color: grey;
    border-radius: 12px;
    cursor: pointer;
    flex-grow: 1;
    padding: 0 1rem;

    &:hover {
        background-color: #719bf6;
    }

    p {

    }
`;

export const ReportViewRow = styled.div`
    display: flex;
    justify-content: space-between;
    //align-items: center;
    flex-direction: column;
    //flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    height: 100%;
    padding: 0 20px;
`;

export const PdfViewFrame = styled.iframe`
    width: 100%;
    height: 200vh;
    border: 1px solid black;
    border-radius: 12px;
`;

export const OpenNewWindow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    width: 64px;
    background-color: grey;
    border-radius: 12px;
    cursor: pointer;

    &:hover {
        background-color: #719bf6;
    }

    p {

    }
`;
