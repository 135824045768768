import {DashboardWrapper} from "../components/DashboardWrapper/DashboardWrapper.styles";
import React from "react";
import ChangePassword from "../components/ChangePassword";

const ChangePasswordPage = () => {
    return (
        <DashboardWrapper>
            <ChangePassword/>
        </DashboardWrapper>
    );
}

export default ChangePasswordPage;