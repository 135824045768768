import React from "react";
import {OptionsContainer, Wrapper} from "./SignOptions.styles";
import GoogleIcon from "../../assets/Google.svg";
import FacebookIcon from "../../assets/Facebook.svg";
import AppleIcon from "../../assets/Apple.svg";
import Mail from "../../assets/mail.svg";
import {useNavigate} from "react-router-dom";

const SignOptions = ({signIn}) => {

    const navigate = useNavigate();

    return (
        <Wrapper>
            {signIn ?
                <p>or Sign in with</p>
                :
                <p>or Sign up with</p>
            }
            <OptionsContainer>
                <a onClick={() => navigate('/login')}>
                    <img src={Mail} alt="Log in with Mail"/>
                </a>
                <img src={GoogleIcon} alt="Log in with Google"/>
                <img src={FacebookIcon} alt="Log in with Facebook"/>
                <img src={AppleIcon} alt="Log in with Apple"/>
            </OptionsContainer>
        </Wrapper>
    )
};

export default SignOptions;