import styled from "styled-components";

export const Wrapper = styled.div`
    margin-top: 44px;
    text-align: center;
`;

export const OptionsContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 24px;

    img {
        width: 48px;
        height: 48px;
        cursor: pointer;
    }

    a {

        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 24px;
            width: 30px;
        }
    }
`;