import React from "react";
import { Wrapper, ProfileImg, AddImg } from "./AddClientImage.styles";
import ProfileImage from "../../assets/profile-img.svg"
import AddIcon from "../../assets/new-test.svg"

const AddClientImage = ({ showText }) => {

    return (
        <Wrapper>
            {showText ??
            <p>Add client image</p>}
            <ProfileImg src={ProfileImage} alt="Patient" />
            <AddImg src={AddIcon} alt="Add" />
        </Wrapper>
    )
};

export default AddClientImage;