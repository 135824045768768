import React from 'react';
import envelopeImage from '../assets/envelope.png'; 

import {
    LeftContainer,
    RightContainer,
    SideWrapper
} from '../components/SideWrapper/SideWrapper.styles';
import LogoAndText from '../components/LogoAndText';

const PostSignupPage = () => {
    return (
        <SideWrapper>
            <LeftContainer>
                <LogoAndText/>
            </LeftContainer>
            <RightContainer>
                <div style={{
                    position: 'absolute',
                    width: '787px',
                    height: '860px',
                    right: '0px',
                    top: '0px'                   
                }}>
                <div style={{
                    boxSizing: 'border-box',
                    position: 'absolute',
                    width: '184px',
                    height: '184px',
                    left: '302px',
                    top: '269px',
                }}>
                    <svg width="184" height="184" viewBox="0 0 184 184" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="92" cy="92" r="91" stroke="#2F80ED" strokeWidth="2"/>
                    </svg>

                    <img 
                        src={envelopeImage} 
                        alt="Envelope" 
                        style={{
                            position: 'absolute',
                            width: '80px',
                            height: '80px',
                            left: 'calc(50% - 40px)', 
                            top: 'calc(50% - 40px)'
                        }}
                    />
                </div>

                    <p style={{
                        position: 'absolute',
                        width: '397px',
                        height: '45px',
                        left: 'calc(50% - 397px/2)',
                        top: '485px',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '32px',
                        lineHeight: '39px',
                        textAlign: 'center',
                        color: '#2F80ED'
                    }}>
                        Verification e-mail sent!
                    </p>                    

                    <p style={{
                        position: 'absolute',
                        width: '457px',
                        height: '57px',
                        left: 'calc(50% - 457px/2)',
                        top: '534px',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '19px',
                        textAlign: 'center',
                        color: '#000000'
                    }}>
                        Almost there! Check your inbox for a verification email to complete your registration. If you don't see it, be sure to check your spam or junk folder.
                    </p>
                </div>

            </RightContainer>
        </SideWrapper>
    );
}

export default PostSignupPage;
