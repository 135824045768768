import api from '../api';

export const getAllPatients = async (doctorId) => {
    try {
        const response = await api.get(`/patients/${doctorId}`, {
            credentials: 'include',
        });

        if (!(response.status === 200)) {
            throw new Error('Network response was not ok');
        }

        return await response.data;
    } catch (error) {
        throw error.message;
    }
};

export const getPatient = async (patientId) => {
    try {
        const response = await api.get(`/patient/${patientId}`, {
            credentials: 'include',
        });

        if (!(response.status === 200)) {
            throw new Error('Network response was not ok');
        }

        return await response.data;
    } catch (error) {
        throw error.message;
    }
};

export const createPatient = async (doctorId, firstName, lastName, email, phone) => {
    try {
        const response = await api.post('/patients',
            {doctorId, firstName, lastName, email, phone},
            {
                credentials: 'include',
            });

        if (!(response.status === 201)) {
            throw new Error('Network response was not ok');
        }

        return await response.data;
    } catch (error) {
        throw error.message;
    }
};


export const searchPatients = async (doctorId, search) => {
    try {
        const response = await api.get(`/patients/search/${doctorId}`,
            {
                params: {
                    q: search
                }
            }
        );

        if (!(response.status === 200)) {
            throw new Error('Network response was not ok');
        }

        return await response.data;
    } catch (error) {
        throw error.message;
    }
}
