import React from "react";
import {useNavigate} from 'react-router-dom';
import {Wrapper} from "./LogoAndText.styles";
import Logo from "../../assets/logo.svg";

const LogoAndText = () => {
    const navigate = useNavigate();

    return (
        <Wrapper>
            <img onClick={() => navigate('/')} src={Logo} alt="logo"/>
            <p>Next Gen Precision Skincare</p>
        </Wrapper>
    )
};

export default LogoAndText;
