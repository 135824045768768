import React from "react";
import {InputField} from "./Input.styles";

const Input = ({type, name, value, placeholder, onChange, required, style, backgroundImg = null, disabled = false}) => {

    return (
        <InputField
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            required={required}
            style={style}
            disabled={disabled}
            // backgroundImg={backgroundImg}
        />
    )
};

export default Input;