import api from "../api";

export const uploadPatientImage = async (patientId, image, rename, config) => {
    const formData = new FormData();
    formData.append('image', image);
    formData.append('patient_id', patientId);
    formData.append('rename', rename);

    return api.post('/patient/upload', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        ...config
    });
}

export const getPatientFilesList = async (patientId) => {
    return api.get(`/patients/${patientId}/images`);
}

export const getFile = (file_name) => {
    return process.env.REACT_APP_API_URL + '/uploads/' + file_name;
}

export const deletePatientFile = async (patientId, fileId) => {
    return api.delete(`/patients/${patientId}/images/${fileId}/delete`);
}

export const getPatientFile = async (patientId, fileId) => {
    return api.get(`/patients/${patientId}/images/${fileId}`);
}
