import React, {useContext, useState} from "react";
import SideMenu from "../SideMenu";
import {DashboardWrapper, Wrapper} from "./Layout.styles";
import {UserContext} from "../../context/UserContext";
import {Navigate} from "react-router-dom";

const Layout = ({children}) => {
    // const showSideMenu = useState();

    const { user } = useContext(UserContext);

    if (!user) {
        return (<div>{children}</div>);
    }

    return (
        <Wrapper>
            <SideMenu />
            {children}
        </Wrapper>
    )
};

export default Layout;
