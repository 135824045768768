import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: -18px;
    gap: 10px;
    flex-wrap: wrap;
    position: relative;

    .webcam {
        width: 100%;
        height: 100%;
    }
`;

export const CamControls = styled.div`
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
`;

