import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
`;

export const DashboardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px 0;
    padding: 20px 32px 30px 32px;
    border-radius: 20px;
    background: rgba(13, 9, 29, 0.40);
    height: 100%;
    overflow-y: auto;
`;

export const DashboardInner = styled.div`
    border-radius: 10px;
    background: rgba(88, 80, 109, 0.30);
    //height: 100%;
    //margin-top: 30px;
    padding: 20px 45px 20px 45px;
    position: relative;
`;
