import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 300px;
    margin-left: 24px;
    margin-right: 24px;

    p {
        text-align: center;
        line-height: normal;
        margin-top: 68px;
        max-width: 450px;
    }

    img {
        cursor: pointer;
        height: 51px;
    }
`;
