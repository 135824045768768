import styled from "styled-components";
import Modal from "styled-react-modal";

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h3 {
        flex: 1;
        margin-left: 15px;
    }
`;

export const Images = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    overflow-y: auto;
    height: 100%;
`;

export const ImageField = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background: #555555;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    padding-bottom: 10px;
    width: 400px;
    height: 350px;
    text-align: center;

    p {
        width: 90%;
        overflow: hidden;
        height: 10%;
    }

    img {
        vertical-align: middle;
        object-fit: cover;
        width: 100%;
        height: 90%;
    }

    video::-webkit-media-controls {
        display: none;
    }

    video {
        width: 100%;
        height: 90%;
        pointer-events: none;

    }
`;

export const StyledModal = Modal.styled`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background: #555555;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    padding-bottom: 10px;
    
    img {
        // width: 350px;
        height: 350px;
        object-fit: fill;
        border-radius: 10px 10px 0 0;
    }
    .bottomModal {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 95%;
        .modalButtonGroup {
            display: flex;
            gap: 10px;
        }
    }
    
    video {
        // width: 350px;
        height: 350px;
        
        object-fit: fill;
    }
`

export const FileField = styled.div`
    video {
        height: 60vh;

        object-fit: fill;
    }

    img {
        // width: 350px;
        height: 60vh;
        object-fit: fill;
        border-radius: 10px 10px 0 0;
    }
`
