import React from 'react';

// Components
import {DashboardWrapper} from '../components/DashboardWrapper/DashboardWrapper.styles';

import DoctorProfile from '../components/DoctorProfile';

const PatientsCabinetPage = () => {

    return (
        <DashboardWrapper>
            <DoctorProfile/>
        </DashboardWrapper>
    );

}

export default PatientsCabinetPage;
