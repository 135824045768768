import React, {useEffect, useState} from 'react';

// Components
import {DashboardWrapper} from '../../../components/DashboardWrapper/DashboardWrapper.styles';
import {FaArrowLeft} from "react-icons/fa";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {IconContext} from "react-icons";
import {FileField, Header} from "../styles";
import {DivRow} from "../../../widgets/GeneralBody";
import {getPatientFilesList} from "../../../utils/api-lists";
import toast from "react-hot-toast";

const CompareFilesPage = () => {
    const navigate = useNavigate();
    const {patientId} = useParams();

    const [searchParams] = useSearchParams();
    const file1Id = searchParams.get('file1Id');
    const file2Id = searchParams.get('file2Id');

    const [file1, setFile1] = useState();
    const [file2, setFile2] = useState();

    function getImages() {
        getPatientFilesList(patientId).then(r => {
            const files = r.data;
            const file1 = files.find(f => f.id === file1Id);
            const file2 = files.find(f => f.id === file2Id);
            setFile1(file1);
            setFile2(file2);
        }).catch(e => {
            toast.error('Failed to get files');
        })
    }

    useEffect(() => {
        document.title = 'Visual analysis: Compare files';
        getImages();

    }, [patientId, file1Id, file2Id]);

    const showWithFileType = ({file}) => {
        switch (file['type']) {
            case 'video':
                return (
                    <video controls>
                        <source src={file['url']} type="video/mp4"/>
                        Your browser does not support the video tag.
                    </video>
                );
            default:
            case 'image':
                return <img src={file['url']} alt="Image"/>;
        }
    }

    return (
        <DashboardWrapper>
            <Header>
                <IconContext.Provider value={{style: {cursor: 'pointer'}}}>
                    <FaArrowLeft onClick={() => navigate(-1)}/>
                </IconContext.Provider>
                <h3>Visual Analysis: Compare Files</h3>
            </Header>
            <DivRow alignItems={'first'} justifyContent={'space-around'}>
                <div>
                    <h4>File 1</h4>
                    <FileField>
                        {
                            file1 &&
                            showWithFileType({file: file1})
                        }
                    </FileField>
                </div>
                <div>
                    <h4>File 2</h4>
                    <FileField>
                        {
                            file2 &&
                            showWithFileType({file: file2})
                        }
                    </FileField>
                    {/*<img src={getFile(file2?.image_name)} alt={file2?.image_name} style={{maxWidth: '100%'}}/>*/}
                </div>
            </DivRow>
        </DashboardWrapper>
    );

}

export default CompareFilesPage;
