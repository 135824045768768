import React from "react";
import { Wrapper } from "./VideoAnalysis.styles";
import videoFile from "../../assets/video_quality_check.mp4";

const VideoAnalysis = () => {

    return (
        <Wrapper>
            <video autoPlay controls>
                <source src={videoFile} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </Wrapper>
    )
};

export default VideoAnalysis;