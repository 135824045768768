import React, { createContext, useState, useContext } from 'react';

const RoleContext = createContext();

export const RoleProvider = ({ children }) => {
    const [roleCheck, setRoleCheck] = useState(null);

    return (
        <RoleContext.Provider value={{ roleCheck, setRoleCheck }}>
            {children}
        </RoleContext.Provider>
    );
};

export const useRole = () => {
    return useContext(RoleContext);
};