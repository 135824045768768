import React from "react";
import CircleButton from "../../widgets/AddIcon";
import {FaX} from "react-icons/fa6";
import {CloseModalWrapper} from "./CloseModal.styles";

const CloseModal = ({onClick}) => {

    return (
        <CloseModalWrapper>
            <CircleButton onClick={onClick}>
                <FaX/>
            </CircleButton>
        </CloseModalWrapper>
    )
};

export default CloseModal;