import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    max-width: 774px;
    width: 100%;
    flex-direction: column;
    position: relative;

    //overflow: hidden;
    padding-right: 44px;
    ////align-items: center;
`;

export const SearchBar = styled.input`
    width: 100%;
    border-radius: 86px;
    background: rgba(204, 200, 207, 0.20);
    line-height: normal;
    padding: 14px 20px;
    border: 1px solid transparent;

    &::placeholder {
        color: rgba(240, 216, 255, 0.70);
        line-height: normal;
    }

    &:focus {
        outline: none !important;
        border: 1px solid rgba(240, 216, 255, 0.70);
    }
`;

export const SearchResults = styled.div`
    z-index: 1;
    position: absolute;
    width: 100%;
    background-color: #462347;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin-top: 1rem;
    padding: 10px 0;
    max-height: 300px;
    overflow-y: scroll;
    scrollbar-color: #5e2b5e #462347;
    scrollbar-width: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    top: 100%;
`;

export const SearchResult = styled.div`
    padding: 10px 20px;
    //color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #5e2b5e;
        border-radius: 20px;
    }
`;
