import React, {useCallback, useEffect, useState} from "react";
import {ListOfPdf, OpenNewWindow, PdfContainer, PdfViewFrame, ReportViewRow} from "./PdfList.styles";
import {useNavigate, useParams} from 'react-router-dom';
import api from "../../utils/api";
import toast from "react-hot-toast";
import {Header, StyledModal} from "../../pages/PatientCabinet/styles";
import {IconContext} from "react-icons";
import {FaArrowLeft, FaRegWindowRestore} from "react-icons/fa";
import {DivRow, GeneralBody} from "../../widgets/GeneralBody";
import CircleButton from "../../widgets/AddIcon";
import {Popup} from "../PatientProfile/PatientProfile.styles";
import CloseModal from "../CloseModal";

const PdfList = () => {
    const navigate = useNavigate();
    const {patientId} = useParams();
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [reports, setReports] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [openingReport, setOpeningReport] = useState(false);
    const [isNewWindow, setIsNewWindow] = useState(false);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    function toggleModal(file) {
        setIsOpen(!isOpen);
        setFile(file);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('report', file);
        const config = {
            onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted);
            }
        };
        try {
            setUploadProgress(0);
            const response = await api.post(`/patients/${patientId}/reports`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                ...config
            });

            if (response.status !== 200) {
                toast.error('Failed to upload report');
                throw new Error('Failed to upload report');
            }
            toast.success('Report uploaded successfully');
            setIsOpen(false);
            setFile(null);
            setUploadProgress(0);

            setMessage('Report uploaded successfully');
            fetchReports(); // Refresh the list of reports
        } catch (error) {
            setMessage(error.message);
        }
    };

    const fetchReports = useCallback(async () => {
        try {
            const response = await api.get(`/patients/${patientId}/reports`);

            if (response.status !== 200) {
                toast.error('Failed to fetch reports');
                throw new Error('Failed to fetch reports');
            }
            toast.success('Reports fetched successfully');

            const data = await response.data;
            setReports(data);
        } catch (error) {
            setError(error.message);
        }
    }, [patientId]);

    useEffect(() => {
        if (patientId) {
            fetchReports();
        }
    }, [patientId, fetchReports]);

    const handleReportClick = async (reportId, openNewWindow) => {
        try {
            setIsNewWindow(openNewWindow);
            setOpeningReport(true);
            const response = await api.get(`/patients/${patientId}/reports/${reportId}`, {
                responseType: 'arraybuffer',
            });

            if (response.status !== 200) {
                toast.error('Failed to fetch report');
                throw new Error('Failed to fetch report');
            }

            console.log(response.data);

            const blob = new Blob([response.data], {type: 'application/pdf'});
            const url = window.URL.createObjectURL(blob);
            setSelectedReport(url);
            setOpeningReport(false);
            if (!openNewWindow) {
                return;
            }
            const pdfWindow = window.open();
            pdfWindow.location.href = url;
        } catch (error) {
            setError(error.message);
        }
    };

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <>
            <Header>
                <IconContext.Provider value={{style: {cursor: 'pointer'}}}>
                    <FaArrowLeft onClick={() => navigate(-1)}/>
                </IconContext.Provider>
                <h3>Glacies Skin Report</h3>
                <CircleButton onClick={() => {
                    setIsOpen(true);
                }}/>
            </Header>
            {/*{selectedReport && <iframe src={selectedReport} title='Report' width="100%" height="80%"/>}*/}
            <GeneralBody>
                {
                    openingReport &&
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <p>Opening report...</p>
                    </div>
                }
                <ReportViewRow>
                    {
                        !openingReport && selectedReport && !isNewWindow &&
                        <PdfViewFrame src={selectedReport} title='Report' width="100%"/>
                    }
                    <ListOfPdf
                        loading={openingReport}
                    >
                        {reports.map((report, index) => (
                            <DivRow justifyContent={"start"}>
                                <p>Report {index + 1}</p>
                                <PdfContainer key={index} onClick={() => handleReportClick(report.id)}>
                                    <p>{report.report_name}</p>
                                </PdfContainer>
                                <OpenNewWindow onClick={() => handleReportClick(report.id, true)}>
                                    <FaRegWindowRestore/>
                                </OpenNewWindow>
                            </DivRow>
                        ))}
                    </ListOfPdf>
                </ReportViewRow>
            </GeneralBody>

            <StyledModal
                isOpen={isOpen}
                onBackgroundClick={() => toggleModal(null)}
                onEscapeKeydown={() => toggleModal(null)}>
                <Popup>
                    <form onSubmit={handleSubmit}>
                        <input type="file" accept=".pdf" onChange={handleFileChange} required/>
                        {
                            file &&
                            <div className="uploadGroup">
                                <button type="submit" className="btn btn-4 pad margin-top-24">Upload PDF report</button>
                                {uploadProgress > 0 && <progress value={uploadProgress} max="100"></progress>}
                            </div>
                        }
                    </form>
                </Popup>
                <CloseModal onClick={() => {
                    setIsOpen(false);
                }}/>
            </StyledModal>
        </>
    )
};

export default PdfList;
