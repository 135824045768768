import React from "react";
import {Circle} from "./AddIcon.style";
import {FaPlus} from "react-icons/fa";

const CircleButton = ({children, onClick, disabled}) => {

    return (
        <Circle onClick={onClick} disabled={disabled}>
            {children ?? <FaPlus size={48}/>}
        </Circle>
    )
};

export default CircleButton;
