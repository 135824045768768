import React, {useEffect, useState} from 'react';

// Components
import {DashboardWrapper} from '../../../components/DashboardWrapper/DashboardWrapper.styles';
import {FaArrowLeft} from "react-icons/fa";
import {useNavigate, useParams} from "react-router-dom";
import {IconContext} from "react-icons";
import {Header} from "../styles";
import {GeneralBody} from "../../../widgets/GeneralBody";
import {uploadPatientImage} from "../../../utils/api-lists";
import toast from "react-hot-toast";
import Input from "../../../components/Input";
import Camera from "../../../components/Camera";
import Modal from "styled-react-modal";
import CloseModal from "../../../components/CloseModal";

const StyledModal = Modal.styled`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FileUploadPage = () => {
    const navigate = useNavigate();
    const {patientId} = useParams();


    const [file, setFile] = useState();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [rename, setRename] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isVideo, setIsVideo] = useState(false);


    useEffect(() => {
        document.title = 'Visual analysis: File upload';
    }, []);

    function toggleModal(isVideo = false) {
        setIsOpen(!isOpen);
        setIsVideo(isVideo);
    }

    function handleChange(event) {
        setUploadProgress(0);
        setFile(event.target.files[0]);
    }

    function base64ToFile(base64, filename) {
        const arr = base64.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }

    function generateRandomNumber() {
        return Math.floor(Math.random() * 1000000);
    }

    function saveImage(image) {
        const file = base64ToFile(image, `webcam-${generateRandomNumber()}.jpg`);
        setFile(file);
        toggleModal();
    }

    function saveVideo(video) {
        setFile(video);
        toggleModal();
    }

    function handleSubmit(event) {
        event.preventDefault();

        const config = {
            onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted);
            }
        };
        console.log(file);
        toast.promise(
            uploadPatientImage(patientId, file, rename, config), {
                loading: 'Uploading...',
                success: 'Upload successful!',
                error: (e) => `Upload failed ${e.response.data.message}`
            })
            .then(() => {
                navigate(-1);
            })
            .catch((e) => {

            });
    }

    function handleRename(event) {
        setRename(event.target.value);
    }


    return (
        <DashboardWrapper>
            <Header>
                <IconContext.Provider value={{style: {cursor: 'pointer'}}}>
                    <FaArrowLeft onClick={() => navigate(-1)}/>
                </IconContext.Provider>
                <h3>Visual Analysis: Upload file</h3>
            </Header>
            <GeneralBody>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="file">Choose a file</label>
                    <input name="file" id="file" type="file" onChange={handleChange}/>
                    <button type="button" className='btn btn-2' onClick={() => toggleModal()}>
                        Take a Photo
                    </button>

                    <button type="button" className='btn btn-2' onClick={() => toggleModal(true)}>
                        Take a Video
                    </button>
                    <Input style={{maxWidth: 300}} name="rename" id="rename" type="text" placeholder="Rename file"
                           onChange={handleRename}/>

                    {
                        file &&
                        <div className="uploadGroup">
                            <button type="submit" className='pl-2 pr-2 btn btn-2'>Upload</button>
                            {uploadProgress > 0 && <progress value={uploadProgress} max="100"></progress>}
                        </div>
                    }

                </form>
            </GeneralBody>

            <StyledModal
                isOpen={isOpen}
                onBackgroundClick={toggleModal}
                onEscapeKeydown={toggleModal}>
                <Camera saveImage={saveImage}
                        isVideo={isVideo}
                        saveVideo={saveVideo}
                />
                <CloseModal onClick={toggleModal}/>
            </StyledModal>
        </DashboardWrapper>
    );

}

export default FileUploadPage;
