import React, {useEffect, useState} from 'react';

// Components
import {DashboardWrapper} from '../../components/DashboardWrapper/DashboardWrapper.styles';
import {Header} from "./styles";
import {IconContext} from "react-icons";
import {FaArrowLeft} from "react-icons/fa";
import {GeneralBody} from "../../widgets/GeneralBody";
import api from "../../utils/api";
import toast from "react-hot-toast";
import {useNavigate, useParams} from "react-router-dom";


const PdfPage = () => {
    const [reportId, setReportId] = useParams();
    const [patientId, setPatientId] = useParams();

    const [selectedReport, setSelectedReport] = useState(null);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const handleReportClick = async () => {
        try {
            const response = await api.get(`/patients/${patientId}/reports/${reportId}`, {
                responseType: 'arraybuffer',
            });

            if (response.status !== 200) {
                toast.error('Failed to fetch report');
                throw new Error('Failed to fetch report');
            }

            console.log(response.data);

            const blob = new Blob([response.data], {type: 'application/pdf'});
            const url = URL.createObjectURL(blob);
            console.log(url);
            setSelectedReport(url);
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        if (patientId && reportId) {
            handleReportClick();
        }
    }, [patientId, reportId]);

    return (
        <DashboardWrapper>
            <Header>
                <IconContext.Provider value={{style: {cursor: 'pointer'}}}>
                    <FaArrowLeft onClick={() => navigate(-1)}/>
                </IconContext.Provider>
                <h3>Glacies Skin Report: PDF</h3>
            </Header>
            <GeneralBody>
                {selectedReport && <iframe src={selectedReport} title='Report' width="100%" height="80%"/>}
            </GeneralBody>
        </DashboardWrapper>
    );
}

export default PdfPage;
