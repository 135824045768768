import React from 'react';

// Components
import {DashboardInner, DashboardWrapper} from '../components/DashboardWrapper/DashboardWrapper.styles';
import DashboardHeader from '../components/DashboardHeader';
import VideoAnalysis from '../components/VideoAnalysis';


const VideoAnalysisPage = () => {

    return (
        <DashboardWrapper>
            <DashboardHeader/>
            <DashboardInner>
                <p>Video Analysis</p>
                <VideoAnalysis/>
            </DashboardInner>
        </DashboardWrapper>
    );

}

export default VideoAnalysisPage;
