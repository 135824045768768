import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: -18px;
    position: relative;
`;

export const ProfileImg = styled.img`
    height: 67px;
    width: 67px;
    margin-left: 24px;
`;

export const AddImg = styled.img`
    height: 23px;
    width: 23px;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
`;