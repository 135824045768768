import React, {useState} from 'react';

// Components
import { Wrapper, DashboardWrapper, DashboardInner } from '../components/DashboardWrapper/DashboardWrapper.styles';
import SideMenu from '../components/SideMenu';
import Input from '../components/Input';
import TextArea from "../components/textarea";
import {Form} from "../components/NewPatient/NewPatient.styles";

const PatientListPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // const response = await createPatient(user.id, firstName, lastName, email, phone);
            // console.log(response);
            setSuccess('Email sent successfully!');
            setError(null);
            setName('');
            setEmail('');
        } catch (err) {
            setError(err);
            setSuccess(null);
        }
    };

    return (
        <DashboardWrapper>
            <div style={
                {
                    textAlign: 'center',
                    maxWidth: '60%',
                    margin: 'auto',
                }
            }>
                <h1>How Glacies Work?</h1>
                <p>At Glacies, we understand your skin's unique narrative. By integrating cutting-edge sequencing technology and microbiome insights with advanced AI, we offer a comprehensive skin analysis that delves beyond the surface to uncover the intricate biology of your skin.
                    Our approach customizes a skincare regimen precisely tailored to your needs. Our proprietary algorithm synthesizes data from three key sources: your skin's microbiome via swab analysis, a detailed questionnaire capturing your unique habits, and facial imaging to construct a distinct skin profile.
                    Ready to discover what your skin truly needs? Let's embark on this journey together.</p>

                <h1 className='padding-top-24'>Contact</h1>
                <Form onSubmit={handleSubmit}>
                    <Input type="text" name="name" placeholder='Name*' value={name} onChange={(e) => setName(e.target.value)} required />
                    <Input type="email" name="email" placeholder='Email*' value={email} onChange={(e) => setEmail(e.target.value)} required />
                    <TextArea name="message" placeholder='Message ...' value={message} onChange={(e) => setMessage(e.target.value)} required />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    {success && <p style={{ color: 'green' }}>{success}</p>}
                    <button type="submit" className="btn btn-2 btn-align-right">Submit</button>
                </Form>
            </div>
        </DashboardWrapper>
    );

}

export default PatientListPage;
