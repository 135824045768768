import styled from "styled-components";

export const InputField = styled.input`
    border-radius: 10px;
    border: 1px solid var(--Input-Stroke, #404D54);
    background: var(--Input-Primary, rgba(194, 233, 255, 0.12));
    border:1px solid transparent;
    height: 43px;
    padding: 0 24px;

    &::placeholder {
        color: rgba(255, 255, 255, 0.40);
        line-height: normal;
    }

    &:focus {
        outline: none !important;
        border:1px solid rgba(240, 216, 255, 0.70);
    }
`;