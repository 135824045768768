import React from 'react';
import FaceDetection from '../components/FaceDetection/FaceDetection';

function FaceDetectionPage() {
  return (
    <div>
      <h1>Face Detection Test Page</h1>
      <FaceDetection />
    </div>
  );
}

export default FaceDetectionPage;
