import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import PropTypes from 'prop-types';
import {isAuthenticated} from './api-lists/login.api';

const ProtectedRoute = ({redirectPath = '/login', children}) => {
    if (!isAuthenticated()) {
        return <Navigate to={redirectPath} replace/>;
    }

    return children ?? <Outlet/>;
};

ProtectedRoute.propTypes = {
    redirectPath: PropTypes.string,
    children: PropTypes.node,
};

export default ProtectedRoute;
