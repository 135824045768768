import React from "react";
import { Wrapper } from "./DashboardHeader.styles";
import Profile from "../Profile";
import Search from "../Search";

const DashboardHeader = () => {

    return (
        <Wrapper>
            <Search />
            <Profile />
        </Wrapper>
    )
};

export default DashboardHeader;