import React from 'react';

// Components
import { Wrapper, DashboardWrapper } from '../components/DashboardWrapper/DashboardWrapper.styles';
import SideMenu from '../components/SideMenu';
import DashboardHeader from '../components/DashboardHeader';
import WelcomeBanner from '../components/WelcomeBanner';
import DashboardBottom from '../components/DashboardBottom';

const HomePage = () => {

    return (
        <DashboardWrapper>
            <DashboardHeader />
            <WelcomeBanner />
            <DashboardBottom />
        </DashboardWrapper>
    );

}

export default HomePage;
