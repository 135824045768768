import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    .header-patient-list {
        margin-right: 20px;
    }
`;

export const PatientListContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 31px;
    gap: 17px;
    overflow-y: auto;
    //height: calc(100vh - 410px);
`;

export const PatientContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: #675178;
    height: 71px;
    padding: 0 20px;
    flex-shrink: 0;
    cursor: pointer;
`;

export const PatientPhoto = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 40px;
`;