import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 230px;
    padding: 71px 20px 0px 30px;
    justify-content: space-between;
    height: calc(100vh - 71px);
`;

export const WrapperCollpased = styled.div`
    display: flex;
    flex-direction: column;
    width: 48px;
    padding: 71px 0px 0px 10px;
    justify-content: space-between;
    height: calc(100vh - 71px);
`;
export const LogoImg = styled.img`
    width: 116px;
    height: 35px;
    cursor: pointer;
    margin-bottom: 5vh;
`;

export const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    .active-page {
        border-radius: 10px;
        background: #565CD9;
    }
`;

export const LinkContainer = styled.div`
    display: flex;
    //width: 163px;
    align-items: center;
    gap: 20px;
    padding: 10px;
    cursor: pointer;
`;

export const ImgContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
`;

export const SideMenuHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5vh;
`
