import React, {createContext, useEffect, useState} from 'react';
import {logout as authLogout} from '../utils/api-lists/login.api';

const UserContext = createContext();

const UserProvider = ({children}) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    const loginUser = (userInfo) => {
        setUser(userInfo);
        localStorage.setItem('user', JSON.stringify(userInfo));
    };

    const logoutUser = () => {
        setUser(null);
        authLogout();
    };

    return (
        <UserContext.Provider value={{user, loginUser, logoutUser}}>
            {children}
        </UserContext.Provider>
    );
};

export {UserContext, UserProvider};
