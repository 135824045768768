import React from "react";
import {ImgTextWrapper, Wrapper} from "./Feature.styles";
import {useNavigate} from "react-router-dom";

const Feature = ({image, text, redirect, isNewPage = false, isUrl = true}) => {

    const navigate = useNavigate();

    const handleFeatureClick = () => {
        // if(redirect) window.location.href = `${redirect}`;
        if (!redirect) return;
        if (isUrl) {
            window.open(redirect, isNewPage ? '_blank' : '_self')
        } else {
            navigate(redirect);
        }
    };

    return (
        <Wrapper onClick={handleFeatureClick}>
            <ImgTextWrapper>
                <img src={image} alt="feature"/>
                <p>{text}</p>
            </ImgTextWrapper>
            {/* <button className="btn btn-3">Submit</button> */}
        </Wrapper>
    )
};

export default Feature;
