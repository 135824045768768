import React from "react";
import {TextAreaField} from "./textarea.styles";

const TextArea = ({ name, value, placeholder, onChange, required, style  }) => {

    return (
        <TextAreaField
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            required={required}
            style={style}
            rows="5"
        />
    )
};

export default TextArea;
