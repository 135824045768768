import api from '../api';

export const getDoctor = async (doctorId) => {
    try {
        const response = await api.get(`/doctor/${doctorId}`, {
            credentials: 'include',
            headers: {}
        });

        if (!(response.status === 200)) {
            throw new Error('Network response was not ok');
        }

        return await response.data;
    } catch (error) {
        throw error.message;
    }
};
