import styled from "styled-components";

export const SideWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const LeftContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media (max-width: 768px) {
        width: 0;
    }

    transition: all 0.5s;
`;

export const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    height: 100vh;
    width: 100%;
    padding: 0 24px 0 24px;

    h2 {
        color: #000;
        text-align: center;
        font-size: 24px;
        line-height: normal;
        max-width: 243px;
    }

    p {
        color: #000;

        span {
            color: #000;
            text-decoration: underline;
            cursor: pointer;
        }
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;
    max-width: 400px;
    margin: 42px 0 24px 0;
`;

export const TextContainer = styled.div`
    margin-top: 27px;

    h3 {
        font-size: 20px;
        color: #000;
        text-align: center;
    }

    h5 {
        color: rgba(0, 0, 0, 0.60);
        text-align: center;
        font-size: 14px;
        line-height: normal;
    }
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
        color: #000;
        font-size: 14px;
        line-height: normal;
        margin-bottom: 4px;
    }

    input {
        width: calc(100% - 22px);
        height: 40px;
        border-radius: 10px;
        margin-bottom: 8px;
        padding: 0 10px;
        border: 1px solid #000;
        color: #000;
    }

    p {
        text-align: center;
        font-size: 14px;
        line-height: normal;
    }

    button {
        height: 40px;
        margin-top: 18px;
    }
`;
