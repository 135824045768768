import styled from "styled-components";

export const Circle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: rgb(40 117 206);
    padding: 12px;
    margin-left: 8px;
    width: 16px;
    height: 16px;
    cursor: pointer;

    // case for disabled
    ${({disabled}) => disabled && `
        background: #ccc;
        cursor: not-allowed;
    `}
`;
