import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    p {
        margin: 0 20px;
    }

    img {
        border-radius: 50%;
    }
`;

export const Add = styled.img`
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 4px;
`;